const languages = [`es`, `en`, `de`]
const siteId = 2
const experiences = [705, 735]

module.exports = {
  siteMetadata: {
    title: "siteTitle",
    description: "siteDescription",
    siteUrl: "https://www.caminobarrancodemasca.com",
    siteId: siteId,
    menuLinks: [
      {
        name: "Inicio",
        path: "/",
        icon: "icon-mini-casa",
      },
      {
        name: "Masca",
        icon: "icon-mini-palmera",
        section: 19,
      },
      {
        name: "Reservar",
        experience: 705,
        icon: "icon-mini-ticket",
      },
      {
        name: "Contacto",
        article: 132,
        icon: "icon-mini-sobre",
      },
      {
        name: "Masca hoy",
        article: 133,
        icon: "icon-mini-sol",
      },
    ],
    footer: [
      {
        name: "Páginas legales",
        styleClass: "col-md-3",
        articles: [
          {
            name: "Términos y condiciones",
            article: 128,
          },
          {
            name: "Privacidad",
            article: 143,
          },
          {
            name: "Cookies",
            article: 144,
          },
          {
            name: "Aviso legal",
            article: 145,
          },
          {
            name: "Gestionar cookies",
            btn: "show-preferencesModal",
          }
        ],
      },
      {
        name: "Explora el camino de masca",
        styleClass: "col-md-4",
        articles: [
          {
            name: 'Gestiona tu reserva',
            article: 134
          },
          {
            name: "Autorización a menores",
            resource: "Autorizacion_menores_Esp.pdf",
            dest: "blank",
          }
        ],
      },
      {
        name: "Empresas colaboradoras",
        styleClass: "col-md-5",
        articles: [
          {
            name:
              "Empresas colaboradoras de Turismo Activo y Viviendas Vacacionales",
            article: 137,
          },
          {
            name: "Para ser empresa de Turismo Activo colaboradora",
            article: 139,
          },
          {
            name: "Empresas de transporte",
            article: 166,
          },
          {
            name: "Acceso empresas colaboradoras",
            url: "https://agencias.caminobarrancodemasca.com"
          }
        ],
      },
    ],
    resources: {
      i18n: [
        "Autorización_menores_Esp.pdf",
        "INFO-TENO-ESP.pdf",
        "1-PARQUE-RURAL-DE-TENO-ES.wav",
        "2-CASERIO-DE-MASCA-ESP.wav",
        "3-BARRANCO-DE-MASCA-ES.wav",
      ],
    },
    languages: languages,
    experiences: experiences,
    cookiesPolicyArticle: 144,
    termsAndConditionsArticle: 128,
    noIndexArticles: [203, 204, 205],
    googleAPIKey: `AIzaSyDdKVpsd7bYd_Wdk6DCtqjaaXH2-3eIYYQ`,
    googleReCaptchaAPIKey: "6Lf759MZAAAAADWgbF4KReNUChvQTCmsuz5vhkr_",
    environment: process.env.ENVIRONMENT,
    gtmTrackingId: process.env.GTM_TRACKING_ID,
    gaTrackingId: process.env.GA_TRACKING_ID,
    volcanoTeideAPIConfig: {
      protocol: "https",
      host: process.env.API_URL,
      port: 443,
      strictSSL: true,
      timeout: 100000,
      site_key: "caminomasca",
    },
    cookies: {
      translations: {
        de: {
          consentModal: {
            title: "Cookies?",
            description: "Wir verwenden Cookies, um Ihr Erlebnis zu verbessern und Ihre Präferenzen beim Navigieren auf unserer Website zu speichern.",
            acceptAllBtn: "Alle akzeptieren",
            acceptNecessaryBtn: "Alles ablehnen",
            showPreferencesBtn: "Einstellungen verwalten",
            footer: "<a href=\"#link_cookies\">Cookies</a>\n<a href=\"#link_terms\">Nutzungsbedingungen</a>"
          },
          preferencesModal: {
            title: "Einwilligungspräferenzen",
            acceptAllBtn: "Alle akzeptieren",
            acceptNecessaryBtn: "Alles ablehnen",
            savePreferencesBtn: "Präferenzen speichern",
            closeIconLabel: "Modal schließen",
            serviceCounterLabel: "Dienste",
            sections: [
              {
                title: "Verwendung von Cookies",
                description: "Cookies sind kleine Textdateien, die Ihr Browser auf Ihrem Gerät speichert, um bestimmte Informationen zu speichern. Mit der gespeicherten und zurückgesendeten Information kann eine Website erkennen, dass Sie sie zuvor mit dem Browser auf Ihrem Endgerät besucht haben. Wir verwenden diese Informationen, um die Website optimal nach Ihren Präferenzen zu organisieren und anzuzeigen. In diesem Prozess wird nur das Cookie auf Ihrem Gerät identifiziert. Persönliche Daten werden nur nach Ihrer ausdrücklichen Zustimmung oder wenn es absolut notwendig ist, um die Nutzung des von uns bereitgestellten Dienstes zu ermöglichen, gespeichert."
              },
              {
                title: "Unbedingt erforderliche Cookies <span class=\"pm__badge\">Immer aktiviert</span>",
                description: "Diese Cookies sind grundlegend für die Funktionsweise der Website und können in unseren Systemen nicht deaktiviert werden. Sie werden normalerweise nur als Reaktion auf Ihre Aktionen beim Anfordern von Diensten gesetzt, wie zum Beispiel das Einstellen Ihrer Datenschutzeinstellungen, das Einloggen oder das Ausfüllen von Formularen.",
                linkedCategory: "necessary"
              },
              {
                title: "Funktionalitäts-Cookies",
                description: "Diese Cookies ermöglichen es der Website, verbesserte Funktionalität und Personalisierung zu bieten, wie das Erinnern an Ihre früheren Auswahlmöglichkeiten und Änderungen an der Benutzeroberfläche. Wenn Sie diese Cookies nicht zulassen, funktionieren einige oder alle dieser Dienste möglicherweise nicht richtig.",
                linkedCategory: "functionality"
              },
              {
                title: "Analytische Cookies",
                description: "Analytische Cookies helfen uns zu verstehen, wie Besucher mit der Website interagieren, indem sie Informationen anonym sammeln und melden. Dies ermöglicht es uns, unser Angebot kontinuierlich zu verbessern, um Ihnen ein optimales Surferlebnis zu bieten.",
                linkedCategory: "analytics"
              },
              {
                title: "Soziale und Werbe-Cookies",
                description: "Soziale Cookies ermöglichen es, soziale Netzwerkplattformen in unsere Webseite zu integrieren, was den Nutzern das Teilen von Inhalten und die Interaktion mit anderen Plattformen erleichtert. Werbe-Cookies sammeln Informationen, um Anzeigen an deine Interessen anzupassen, wodurch die Relevanz der Werbekampagnen, die du siehst, erhöht wird. Beide tragen zu einer personalisierten Nutzererfahrung bei und können helfen, die von uns angebotenen Dienste zu erhalten.",
                linkedCategory: "social"
              }
            ]
          }
        },
        en: {
          consentModal: {
            title: "cookies?",
            description: "We use cookies to enhance your experience and remember your preferences when navigating our site.",
            acceptAllBtn: "Accept all",
            acceptNecessaryBtn: "Reject all",
            showPreferencesBtn: "Manage preferences",
            footer: "<a href=\"#link_cookies\">Cookies</a>\n<a href=\"#link_terms\">Terms and Conditions</a>"
          },
          preferencesModal: {
            title: "Consent Preferences",
            acceptAllBtn: "Accept all",
            acceptNecessaryBtn: "Reject all",
            savePreferencesBtn: "Save preferences",
            closeIconLabel: "Close modal",
            serviceCounterLabel: "Services",
            sections: [
              {
                title: "Use of Cookies",
                description: "Cookies are small text files that your browser places on your device to store certain information. By using the information that is stored and returned, a website can recognize that you have previously visited using the browser on your end device. We use this information to organize and display the website optimally according to your preferences. Within this process, only the cookie on your device is identified. Personal data is only stored after your express consent or when it is absolutely necessary to allow the use of the service provided by us and which you access."
              },
              {
                title: "Strictly Necessary Cookies <span class=\"pm__badge\">Always Enabled</span>",
                description: "These cookies are fundamental for the functioning of the website and cannot be disabled in our systems. They are usually set only in response to your actions when requesting services, such as setting your privacy preferences, logging in, or filling out forms.",
                linkedCategory: "necessary"
              },
              {
                title: "Functionality Cookies",
                description: "These cookies enable the website to offer enhanced functionality and personalization, such as remembering your previous selections and changes to the user interface. If you do not allow these cookies, some or all of these services may not function properly.",
                linkedCategory: "functionality"
              },
              {
                title: "Analytical Cookies",
                description: "Analytical cookies help us understand how visitors interact with the website by collecting and reporting information anonymously. This allows us to continuously improve our offering to provide you with an optimal browsing experience.",
                linkedCategory: "analytics"
              },
              {
                title: "Social and Advertising Cookies",
                description: "Social cookies integrate social media platforms into our website, allowing users to share content and interact with other platforms. Advertising cookies collect information to tailor ads to your interests, enhancing the relevance of the advertising campaigns you see. Both contribute to a personalized user experience and can help sustain the services we offer.",
                linkedCategory: "social"
              }
            ]
          }
        },
        es: {
          consentModal: {
            title: "¿Cookies?",
            description: "Utilizamos cookies para mejorar tu experiencia y recordar tus preferencias al navegar en nuestro sitio.",
            acceptAllBtn: "Aceptar todo",
            acceptNecessaryBtn: "Rechazar todo",
            showPreferencesBtn: "Gestionar preferencias",
            footer: "<a href=\"#link_cookies\">Cookies</a>\n<a href=\"#link_terms\">Términos y condiciones</a>"
          },
          preferencesModal: {
            title: "Preferencias de Consentimiento",
            acceptAllBtn: "Aceptar todo",
            acceptNecessaryBtn: "Rechazar todo",
            savePreferencesBtn: "Guardar preferencias",
            closeIconLabel: "Cerrar modal",
            serviceCounterLabel: "Servicios",
            sections: [
              {
                title: "Uso de Cookies",
                description: "Las cookies son pequeños archivos de texto que su navegador coloca en su dispositivo para almacenar cierta información. Al usar la información que se almacena y se devuelve, un sitio web puede reconocer que usted ha accedido y visitado previamente usando el navegador en su dispositivo final. Utilizamos esta información para organizar y mostrar el sitio web de manera óptima de acuerdo con sus preferencias. Dentro de este proceso, solo se identifica la cookie en su dispositivo. Los datos personales solo se almacenan después de su consentimiento expreso o cuando esto es absolutamente necesario para permitir el uso del servicio proporcionado por nosotros y al que usted accede."
              },
              {
                title: "Cookies Estrictamente Necesarias <span class=\"pm__badge\">Siempre Habilitado</span>",
                description: "Estas cookies son fundamentales para el funcionamiento del sitio web y no se pueden desactivar en nuestros sistemas. Por lo general, se configuran solo en respuesta a tus acciones realizadas al solicitar servicios, como configurar tus preferencias de privacidad, iniciar sesión o llenar formularios.",
                linkedCategory: "necessary"
              },
              {
                title: "Cookies de Funcionalidad",
                description: "Estas cookies permiten que el sitio web ofrezca una funcionalidad y personalización mejoradas, como recordar tus selecciones anteriores y cambios en la interfaz de usuario. Si no permites estas cookies, algunos o todos estos servicios podrían no funcionar correctamente.",
                linkedCategory: "functionality"
              },
              {
                title: "Cookies Analíticas",
                description: "Las cookies analíticas nos ayudan a entender cómo interactúan los visitantes con el sitio web, recogiendo y reportando información de manera anónima. Esto nos permite mejorar continuamente nuestra oferta para proporcionarte una experiencia de navegación óptima.",
                linkedCategory: "analytics"
              },
              {
                title: "Cookies Sociales y Publicidad",
                description: "Las cookies sociales permiten integrar funciones de redes sociales en nuestro sitio web, facilitando la compartición de contenido y la interacción con otras plataformas. Las cookies de publicidad recopilan información para personalizar los anuncios según tus intereses, mejorando la relevancia de las campañas publicitarias que ves. Ambas contribuyen a una experiencia de usuario personalizada y pueden ayudar a mantener los servicios que ofrecemos.",
                linkedCategory: "social"
              }
            ]
          }
        }
      }
    },
    contactFormURL:
      "https://63fl5qoyx5.execute-api.eu-west-1.amazonaws.com/pre/contacto",
    appAPIKey: "caminomasca",
    statusAPIURL: "https://api.volcanoteide.com/widgets/status",
    roadStatusURL: "http://cic.tenerife.es/e-Traffic3/Control?act=detMapInci",
    tide1StatusURL:
      "http://www.aemet.es/es/eltiempo/prediccion/playas/la-arena-3804004",
    tide2StatusURL:
      "http://www.aemet.es/es/eltiempo/prediccion/playas/la-ballenita-3801005",
  },
  plugins: [
    `gatsby-plugin-react-helmet`,
    {
      resolve: `gatsby-plugin-sass`,
      options: {
        precision: 6,
      },
    },
    {
      resolve: `volcanoteide-api-plugin`,
      options: {
        baseURL: `https://` + process.env.API_URL,
        languages: languages,
        apiKey: `caminomasca`,
        siteId: siteId,
        experiences: experiences,
        resources: [
          {
            name: "Autorizacion_menores_Esp.pdf",
            i18n: {
              es: "Autorizacion_menores_Esp.pdf",
              en: "Autorizacion_menores_ingles.pdf",
              de: "Autorizacion_menores_Aleman.pdf",
            },
          },
          {
            name: "INFO-TENO-ESP.pdf",
            i18n: {
              es: "INFO-TENO-ESP.pdf",
              en: "INFO-TENO-ENG.pdf",
              de: "INFO-TENO-DE.pdf",
            },
          },
          {
            name: "1-PARQUE-RURAL-DE-TENO-ES.wav",
            i18n: {
              es: "1-PARQUE-RURAL-DE-TENO-ES.wav",
              en: "1-PARQUE-RURAL-DE-TENO-EN.wav",
              de: "1-PARQUE-RURAL-DE-TENO-DE.wav",
            },
          },
          {
            name: "2-CASERIO-DE-MASCA-ESP.wav",
            i18n: {
              es: "2-CASERIO-DE-MASCA-ESP.wav",
              en: "2-CASERIO-DE-MASCA-EN.wav",
              de: "2-CASERIO-DE-MASCA-DE.wav",
            },
          },
          {
            name: "3-BARRANCO-DE-MASCA-ES.wav",
            i18n: {
              es: "3-BARRANCO-DE-MASCA-ES.wav",
              en: "3-BARRANCO-DE-MASCA-EN.wav",
              de: "3-BARRANCO-DE-MASCA-DE.wav",
            },
          },
        ],
      },
    },
    `gatsby-plugin-sharp`,
    `gatsby-transformer-sharp`,
    {
      resolve: `gatsby-plugin-s3`,
      options: {
        bucketName: process.env.S3_BUCKET_NAME,
        generateRoutingRules: false,
        generateRedirectObjectsForPermanentRedirects: false,
      },
    },
    {
      resolve: `gatsby-plugin-react-i18next`,
      options: {
        path: `${__dirname}/src/locales`,
        languages: languages,
        defaultLanguage: ``,

        // you can pass any i18next options
        // pass following options to allow message content as a key
        i18nextOptions: {
          interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
          },
          keySeparator: false,
          nsSeparator: ":",
        },
        pages: [
          {
            matchPath: "/",
            getLanguageFromPath: false,
          },
          {
            matchPath: "/booking",
            getLanguageFromPath: false,
          },
          {
            matchPath: "/checkout",
            getLanguageFromPath: false,
          },
          {
            matchPath: "/checkout/result",
            getLanguageFromPath: false,
          },
          {
            matchPath: "/checkout/payment",
            getLanguageFromPath: false,
          },
          {
            matchPath: "/404",
            getLanguageFromPath: false,
          },
          {
            matchPath: "/:lang/(.*)",
            getLanguageFromPath: true,
          },
        ],
      },
    },
  ],
}
